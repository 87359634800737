import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout/layout"
import Pagination from "../components/pagination/pagination"

export default ({ data, pageContext: { totalPages, currentPage } }) => {
  return (
    <Layout>
      <div>
        <h1>Projects List</h1>
        <Pagination numPages={totalPages} currentPage={currentPage} />
        {/* <div dangerouslySetInnerHTML={{ __html: post.html }} /> */}
        {data.allMarkdownRemark.edges.map(edge => {
          const {
            id,
            frontmatter: { title, date },
            fields: { slug },
          } = edge.node

          return (
            <li key={id}>
              {" "}
              <Link to={slug}>
                {title} - {date}
              </Link>
            </li>
          )
        })}
        <Pagination numPages={totalPages} currentPage={currentPage} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query($skip: Int!, $take: Int!) {
    allMarkdownRemark(
      skip: $skip
      limit: $take
      sort: { fields: [frontmatter___date] }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            date
          }
        }
      }
    }
  }
`
