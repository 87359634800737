import styled from "styled-components"

export const PaginationListStyles = styled.ul`
  margin: 20px 0;

  @media (max-width: 600px) {
    text-align: center;
  }
`

export const PaginationListItemStyles = styled.li`
  list-style-type: none;
  display: inline-block;
  margin-right: 5px;

  & > a {
    padding: 5px 10px;
    background-color: ${props => (props.isCurrent ? "salmon" : "lightsalmon")};
    color: #fff;
    text-decoration: none;

    &:hover {
      background-color: darksalmon;
      text-decoration: underline;
    }
  }

  &:last-child {
    margin-right: 0;
  }
`
