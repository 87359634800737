import React from "react"
import { Link } from "gatsby"

import {
  PaginationListItemStyles,
  PaginationListStyles,
} from "./pagination-styles"

export default ({ numPages, currentPage }) => {
  const pagesArray = Array.from({ length: numPages }).map(
    (_, index) => index + 1
  )
  return (
    <>
      <PaginationListStyles>
        {currentPage !== 1 && (
          <>
            <PaginationListItemStyles>
              <Link to={`/projects/1`}>First</Link>
            </PaginationListItemStyles>
            <PaginationListItemStyles>
              <Link to={`/projects/${currentPage - 1}`}>Prev</Link>
            </PaginationListItemStyles>
          </>
        )}
        {pagesArray.map(page => (
          <PaginationListItemStyles key={page} isCurrent={currentPage === page}>
            <Link to={`/projects/${page}`}>{page}</Link>
          </PaginationListItemStyles>
        ))}
        {currentPage !== numPages && (
          <>
            <PaginationListItemStyles>
              <Link to={`/projects/${currentPage + 1}`}>Next</Link>
            </PaginationListItemStyles>
            <PaginationListItemStyles>
              <Link to={`/projects/${numPages}`}>Last</Link>
            </PaginationListItemStyles>
          </>
        )}
      </PaginationListStyles>
    </>
  )
}
